<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Details
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="Title"
            v-model="fields.tenant_title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tenant_title')"
            :error-messages="errors['tenant_title']"
          ></v-text-field>
          <v-text-field
            label="First Name *"
            v-model="fields.tenant_first_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tenant_first_name')"
            :error-messages="errors['tenant_first_name']"
          ></v-text-field>
          <v-text-field
            label="Surname *"
            v-model="fields.tenant_surname"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tenant_surname')"
            :error-messages="errors['tenant_surname']"
          ></v-text-field>
          <v-text-field
            label="Middle Name(s)"
            v-model="fields.tenant_middle_names"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tenant_middle_names')"
            :error-messages="errors['tenant_middle_names']"
          ></v-text-field>

          <v-menu
            v-model="startDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Tenant Dob"
                v-model="startDateFormatted"
                prepend-inner-icon="mdi-calendar"
                outlined
                background-color="white"
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="max"
              v-model="fields.tenant_dob"
              @input="startDatePicker = false"
              first-day-of-week="1"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            label="Nationality *"
            v-model="fields.tenant_nationality"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tenant_nationality')"
            :error-messages="errors['tenant_nationality']"
          ></v-text-field>
          <v-text-field
            label="Mobile Number *"
            v-model="fields.tenant_mobile"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tenant_mobile')"
            :error-messages="errors['tenant_mobile']"
          ></v-text-field>
          <v-text-field
            label="Email *"
            v-model="fields.tenant_email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tenant_email')"
            :error-messages="errors['tenant_email']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      max: new Date().toISOString().slice(0, 10),
      dialog: false,
      loading: false,
      isEditing: false,
      startDatePicker: false,
      form: {},
      fields: {
        tenant_title: null,
        tenant_first_name: null,
        tenant_middle_names: null,
        tenant_surname: null,
        tenant_dob: null,
        form_title: "details",
        tenant_nationality: null,
        tenant_mobile: null,
        tenant_email: null,
      },
      errors: {},
    };
  },

  computed: {
    startDateFormatted() {
      return this.fields.tenant_dob;
    },
  },

  methods: {
    open(form = null) {
      if (form !== null) {
        this.form = form;
        this.isEditing = true;
        this.fields.tenant_title = form.tenant_title;
        this.fields.tenant_first_name = form.tenant_first_name;
        this.fields.tenant_middle_names = form.tenant_middle_names;
        this.fields.tenant_surname = form.tenant_surname;

        this.fields.tenant_dob = moment(form.tenant_dob, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );

        this.fields.tenant_nationality = form.tenant_nationality;
        this.fields.tenant_mobile = form.tenant_mobile;
        this.fields.tenant_email = form.tenant_email;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.formId = this.form.application_id;
      }

      this.$store
        .dispatch("easylets/applicationformsStore/saveForm", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.form = {};
      this.fields = {
        tenant_title: null,
        tenant_first_name: null,
        tenant_middle_names: null,
        tenant_surname: null,
        tenant_dob: null,
        tenant_nationality: null,
        tenant_mobile: null,
        tenant_email: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
