<template>
  <div>
    <v-card outlined class="mb-6">
      <v-card-subtitle class="d-flex justify-start align-center grey lighten-5">
        <div class="flex-grow-1">Details</div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              @click="$refs.detailsDialog.open(form)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Customer's Information</span>
        </v-tooltip>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <th>Title</th>
              <td>{{ form.tenant_title }}</td>
            </tr>
            <tr>
              <th>First Name</th>
              <td>{{ form.tenant_first_name }}</td>
            </tr>
            <tr>
              <th>Middle Name(s)</th>
              <td>{{ form.tenant_middle_names }}</td>
            </tr>
            <tr>
              <th>Surname</th>
              <td>
                {{ form.tenant_surname }}
              </td>
            </tr>
            <tr>
              <th>Date of Birth</th>
              <td>
                {{ form.tenant_dob }}
              </td>
            </tr>
            <tr>
              <th>Nationality</th>
              <td>
                {{ form.tenant_nationality }}
              </td>
            </tr>
            <tr>
              <th>Mobile Number</th>
              <td>
                {{ form.tenant_mobile }}
              </td>
            </tr>
            <tr>
              <th>Email address</th>
              <td>
                {{ form.tenant_email }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="form.tenant_type == 'student'">
          <tbody>
            <tr>
              <th>University</th>
              <td>{{ form.student_university }}</td>
            </tr>
            <tr>
              <th>University Course</th>
              <td>{{ form.student_university_course }}</td>
            </tr>
            <tr>
              <th>Level of qualification</th>
              <td>{{ form.student_level_of_qualification }}</td>
            </tr>
            <tr>
              <th>Year of graduation</th>
              <td>
                {{ form.student_year_of_graduation }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <details-dialog ref="detailsDialog" />
  </div>
</template>

<script>
import DetailsDialog from "./components/DetailsDialog.vue";

export default {
  components: {
    DetailsDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Forms",
          disabled: false,
          to: { name: "module-easylets-applicationforms" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },
};
</script>
